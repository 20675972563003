import React from "react";
import logo from '../../logo.png';

function PrivacyPolicy() {

    return (

        <div style={{ width: "80%", margin: "0 auto", textAlign: "justify" }}>
            <img src={logo} property="og:image" alt="Logo" width="200" height="200" />
            <h1 style={{ textAlign: "initial" }}>POLÍTICA DE PRIVACIDADE</h1>
            <p></p>
            <p>
                Leia com atenção nossa Política de Privacidade. Ao instalar, cadastrar, acessar, registrar este aplicativo, você aceitará todas as disposições desta Política de Privacidade, concordando sua vinculação integral às condições de uso de livre e espontânea vontade.
            </p>
            <p></p>
            <p></p>
            <h2 style={{ textAlign: "initial" }}>DAS INFORMAÇÕES COLETADAS</h2>
            <p>
                A vigente Política de Privacidade abrange informações referente ao modo como procedemos, total ou parcialmente, de forma automatizada ou não, os dados pessoais dos usuários que acessam nosso aplicativo. Seu objetivo é tornar o mais claro possível aos interessados acerca dos tipos de dados que são coletados, dos motivos da coleta e da forma como o usuário poderá atualizar, gerenciar ou excluir estas informações.
            </p>
            <p>
                O aplicativo coleta dados como: nome, sobrenome, e-mail, CPF, data de nascimento, dentre outros. O seu nome e o e-mail são utilizados para identiﬁcá-lo no aplicativo. O CPF e sua data de nascimento servem para veriﬁcar se você possui a idade mínima de 18 (dezoito) anos. A coleta de demais informações tem como objetivo único e exclusivo para a manutenção e o aprimoramento dos serviços disponíveis, buscando a evolução contínua do aplicativo.
            </p>
            <p>
                Esta Política de Privacidade foi elaborada em conformidade com a Lei Federal n.º 12.965 de 23 de abril de 2014 (Marco Civil da Internet), com a Lei Federal n.º 13.709, de 14 de agosto de 2018 (lei de proteção de Dados Pessoais), e com o Regulamento UE n.º 2016/679 de 27 de abril de 2016 (Regulamento Geral Europeu de Proteção de Dados pessoais – RGDP.)
            </p>
            <p>
                A presente Política de Privacidade poderá sofrer alterações e atualizações em decorrência de eventual atualização normativa, razão pela qual se convida o usuário a consultar periodicamente esta seção.
            </p>
            <h2 style={{ textAlign: "initial" }}>DA SEGURANÇA DOS DADOS PESSOAIS</h2>
            <p>
                A IHM COMERCIO INTELIGENTE LTDA (CNPJ: 37.232.275/0001-60), doravante “empresa”, garante a segurança e privacidade da identidade a todos os seus USUÁRIOS, sendo estes entendidos como toda e qualquer pessoa interessada direta ou indiretamente, nos serviços e sistemas de propriedade ou gerenciados pela empresa incluindo o aplicativo SHOPPBUD, painel admin SHOPPBUD, portal SHOPPBUD, e quaisquer outros sistemas de propriedade ou gerenciados pela empresa.
            </p>
            <p>
                A    empresa    informa    que    a    utilização    de    serviços    disponibilizados    nos    sites    e    aplicativos somente poderá ser realizada mediante registro do USUÁRIO.
            </p>
            <p>
                Mediante a atual política, a empresa assegura a privacidade e segurança de todos os dados cadastrados em sua base por sistemas avançados de criptograﬁa ou semelhantes. Tais dados somente serão utilizados e divulgados pela empresa para o alcance dos serviços ligados ao seu objeto social.
            </p>
            <p>
                O USUÁRIO cadastrado no sistema da empresa, deverá possuir dados de acesso aos serviços disponibilizados nos canais da empresa. O USUÁRIO será exclusivamente responsável pela manutenção do sigilo e da segurança de seus dados, excluindo a empresa de quaisquer responsabilidades pelo uso indevido de tais informações.
            </p>
            <p>
                O USUÁRIO não deverá revelar informações de seus dados a ninguém, nem utilizar dados de outro USUÁRIO. O USUÁRIO é integralmente responsável por todas as atividades que ocorram em sua conta de acesso e concorda em notiﬁcar imediatamente a empresa a respeito de qualquer utilização não autorizada ou de qualquer outra violação de segurança.
            </p>
            <p>
                A    empresa    não    será    responsável    por    qualquer    dano    ocasionado    em    decorrência    do    uso desautorizado dos dados do USUÁRIO
            </p>
            <p>
                A empresa não será responsável por qualquer dano ocasionado em decorrência do uso desautorizado dos dados do USUÁRIO por licenciados e fornecedores de sistemas contratados da empresa, os quais estão autorizados a ter acesso aos dados do USUÁRIO.
            </p>
            <p>
                A empresa se reserva o total direito de suspender o acesso do USUÁRIO e, inclusive recusar futuro cadastramento, caso suspeite que o USUÁRIO tenha utilizado informações falsas ao cadastrar-se.
            </p>
            <p>
                Todas as informações a respeito de cadastro para acesso aos canais do A empresa, quando exigidas, são de uso exclusivo do USUÁRIO e não devem ser repassadas a terceiros. Os dados devem ser sempre protegidos e, no encerramento das operações, deve-se ter o cuidado de sair dos sites e aplicativos de forma apropriada (logout).
            </p>
            <p>
                O cadastro apenas será efetivado mediante a prévia análise e concordância pelo USUÁRIO da presente política de privacidade. As informações sobre a navegação do USUÁRIO (endereço IP, cookies, páginas acessadas, entre outras) nos canais da empresa podem ser armazenadas por prazo indeterminado a ﬁm de gerar massa de dados para a melhoria dos serviços oferecidos.
            </p>
            <p>
                A    empresa    poderá    se    recusar    ou    impedir    o    acesso    dos    USUÁRIOS    que    descumpram    as condições estabelecidas no presente Termo.
            </p>
            <p>
                Vale    lembrar    que,    havendo   conﬂito   nas   informações   de   condições   de   uso,   prevalecerão   os termos adicionais, já que estes vieram para ﬁrmar melhorias.
            </p>
            <h2 style={{ textAlign: "initial" }}>DO USO DAS INFORMAÇÕES</h2>
            <p>A empresa compromete-se a:</p>
            <p>
                (I) Não divulgar, sem autorização, os dados dos USUÁRIOS para outros ﬁns que não sejam para consecução dos seus serviços, salvo as exceções constantes na presente “Política de Privacidade”;
            </p>
            <p>
                (II) Adotar como política o compromisso de não divulgar informações sobre os acessos realizados por seus USUÁRIOS, a menos que seja obrigado a fazê-lo mediante ordem judicial ou no estrito cumprimento de norma legal ou contratual;
            </p>
            <p>
                (III)  Utilizar-se  de  recursos  técnicos  com o objetivo de aprimorar constantemente seus serviços e de adequá-los cada vez mais às necessidades dos USUÁRIOS.
            </p>
            <p>O USUÁRIO compromete-se a:</p>
            <p>
                (I)  Cumprir  as  regras  de  utilização,  como  descrito de forma mais detalhada adiante e não violar nenhum componente de segurança;
            </p>
            <p>
                (II) Não tentar e nem auxiliar terceiros a contornar, praticar engenharia reversa, desagregar ou, por qualquer outra forma, interferir com quaisquer componentes de segurança a respeito dessas regras de utilização, para qualquer ﬁnalidade;
            </p>
            <p>
                (III)    Não    efetuar    qualquer    tentativa    de    acesso   ao   cadastro   de   terceiros,   a   menos   que   seja devidamente autorizado;
            </p>
            <p>
                (IV) Não modiﬁcar o aplicativo de qualquer modo ou forma ou utilizar versões modiﬁcadas do SISTEMA empresa, para quaisquer ﬁns, inclusive para obtenção de acesso não autorizado aos serviços oferecidos pelo aplicativo;
            </p>
            <p>
                (V)    Manter    as    informações    pessoais    e    cadastrais    sempre    atualizadas   e   não   as   divulgar   a terceiros;
            </p>
            <p>
                (VI) Responder, civil e criminalmente, pela veracidade das informações cadastrais.
            </p>
            <p>
                O USUÁRIO ﬁca desde já ciente de que as informações disponibilizadas através do site e aplicativos contêm uma estrutura de segurança, usando tecnologia que protege informaçõesdigitais   e   limita   o   uso   das   informações   sigilosas,   submetendo-as   à   determinadas   regras   de utilização estabelecidas pela empresa e por seus representantes.
            </p>
            <p>
                A empresa, alerta que violações de segurança de rede ou de sistema poderão resultar em responsabilização civil e/ou criminal. Ao proceder com o download do aplicativo e com o acesso aos sistemas disponibilizados pela empresa, o USUÁRIO autoriza a empresa e seus parceiros a entrarem em contato via aplicativo de mensagens e e-mail, e encaminhar materiais informativos promocionais e institucionais através do endereço de e-mail informado no cadastro.
            </p>
            <h2 style={{ textAlign: "initial" }}>DO SIGILO DAS INFORMAÇÕES</h2>
            <p>
                A política de privacidade da empresa tem como objetivo garantir que quaisquer informações e dados pessoais dos USUÁRIOS não serão fornecidos, publicados ou comercializados em quaisquer circunstâncias a menos que seja obrigada a fazê-lo em função dos seguintes casos:
            </p>
            <p>
                (I)   para   o   cumprimento   de   qualquer   ordem   judicial   ou   cumprimento   de   ordem   proferida   por órgão regulatório competente;
            </p>
            <p>
                (II)   para   o   cumprimento  de  disposição  constante  na  legislação  brasileira  atual  ou  que  venha  a vigorar;
            </p>
            <p>
                (III) para o cumprimento dos termos de serviço, de acordo com o presente instrumento;
            </p>
            <p>
                (IV)     para     responder     às    reclamações    de    que    algum    conteúdo    fornecido    viole    direitos    de terceiros.
            </p>
            <p>
                (V)   para   atender   aos   interesses   legítimos   do   USUÁRIO   ou   de   terceiro   como   identiﬁcação   de suspeitos de furtos de produtos em lojas ou de utilização irregular das lojas ou dos sistemas.
            </p>
            <p>
                Os dados pessoais informados pela empresa são armazenados em um banco de dados reservado e com acesso restrito a alguns funcionários habilitados que são obrigados, por contrato, a manter a conﬁdencialidade das informações e não as utilizar inadequadamente.
            </p>
            <h2 style={{ textAlign: "initial" }}>DA PROPRIEDADE INTELECTUAL</h2>
            <p>
                Os direitos autorais de todo conteúdo disponível nos canais da empresa, incluindo textos, páginas, ilustrações, gráﬁcos, relatórios, dados técnicos e matérias, são protegidos pela Lei nº 9.610/1998. A reprodução, adaptação, modiﬁcação ou utilização não autorizada do conteúdo disponibilizado pela empresa, parcial ou integralmente, é expressamente proibida.
            </p>
            <h2 style={{ textAlign: "initial" }}>CADASTRAMENTO – INFORMAÇÕES E DADOS</h2>
            <p>
                Para ser um USUÁRIO da empresa, é necessário que o interessado realize um cadastro na plataforma, site ou aplicativo da empresa, oferecendo dados pessoais exatos e completos. A empresa está expressamente autorizada a conﬁrmar e consultar esses dados pessoais informados em entidades públicas, companhias especializadas e bancos de dados. A empresa está expressamente autorizada também a compartilhar os dados dos USUÁRIOS com os licenciados com contrato vigente com a empresa. As informações obtidas por essas entidades serão tratadas de forma conﬁdencial. Para a realização do cadastro, o USUÁRIO deverá fornecer os seguintes dados pessoais:
            </p>
            <ul>
                <li>
                    <p>Nome completo;</p>
                </li>
                <li>
                    <p>Data de nascimento;</p>
                </li>
                <li>
                    <p>Endereço de e-mail;</p>
                </li>
                <li>
                    <p>Endereço postal;</p>
                </li>
                <li>
                    <p>Número de telefone;</p>
                </li>
                <li>
                    <p>Número de CPF.</p>
                </li>
            </ul>
            <p>
                É de responsabilidade do USUÁRIO garantir a veracidade dos dados, além de mantê-los atualizados. A empresa coleta e armazena automaticamente algumas informações sobre a atividade dos USUÁRIOS cadastrados e visitantes de seu site/aplicativo. Tais informações podem incluir referências ao comportamento do USUÁRIO no site/aplicativo, como as telas visitadas, o tempo gasto em cada uma, os botões que foram clicados e as buscas realizadas, o endereço IP, versões dos sistemas operacionais e softwares sendo usadas, geolocalização do aparelho, entre outras.
            </p>
            <p>&nbsp;</p>
            <h2 style={{ textAlign: "initial" }}>INFORMAÇÕES NÃO PESSOAIS</h2>
            <p>
                A empresa considera o termo “informações e dados pessoais” os conteúdos que permitam identiﬁcar o USUÁRIO. Todavia, não considera como informações os dados transmitidos de forma anônima, impedindo, portanto, que o USUÁRIO seja identiﬁcado especiﬁcamente com as “Informações Não-Pessoais”. Sendo assim, a empresa poderá usar, divulgar e transferir tais informações (Informações Não-pessoais) para quaisquer ﬁnalidades. São exemplos desses dados, não se limitando a esses, idade, preferências individuais, idioma, CEP e código de área. Ademais, visando fornecer informações abalizadas e entender quais as partes de nossosserviços mais atraem interesse, a empresa obterá informações dos USUÁRIOS no site/aplicativo. Dados agregados são consideradas informações não-pessoais para ﬁns desta Política de Privacidade.
            </p>
            <h2 style={{ textAlign: "initial" }}>DA COMERCIALIZAÇÃO DE DADOS</h2>
            <p>
                A empresa, em hipótese alguma, divulga informações pessoais dos USUÁRIOS para terceiros com ﬁns comerciais, sem seu consentimento explícito. Não obstante, a empresa tem a plena liberdade a remeter aos USUÁRIOS oportunidades que considerar relevantes a seu perﬁl. Por padrão, todos os novos USUÁRIOS cadastrados estão, automaticamente, inscritos em tais listas de divulgação de oportunidades podendo, ainda, solicitar o cancelamento da inscrição nessas listas clicando no link de cancelamento de inscrição presente ao ﬁnal dos e-mails enviados para essas listas.
            </p>
            <h2 style={{ textAlign: "initial" }}>
                DAS ALTERAÇÕES NORMATIVAS NA POLÍTICA DE PRIVACIDADE
            </h2>
            <p>
                A empresa se reserva o direito de, a qualquer momento e, periodicamente, atualizar, rever, complementar ou, de qualquer outra forma, modiﬁcar a presente “Política de Privacidade” e impor regras, políticas, termos ou condições novas ou adicionais ao uso dos serviços oferecidos por meio dos canais acima citados, ou ainda, interações com o sistema de sua propriedade. Sendo a presente Política de Privacidade alterada de forma signiﬁcativa, será informada aos USUÁRIOS pela empresa mediante notiﬁcação virtual, e caso não sendo aderida pelo USUÁRIO, o USUÁRIO deverá manifestar sua vontade de exclusão do sistema empresa através de contato pelo e-mail privacidade@inhousemarket.com.br.
            </p>
            <h2 style={{ textAlign: "initial" }}>DAS ALTERAÇÕES DE TERMOS INTERNOS</h2>
            <p>
                A presente versão desta política de privacidade foi atualizada pela última vez em 15/11/2021.
            </p>
            <p>
                O editor se reserva o direito de modiﬁcar, a qualquer momento e sem qualquer aviso prévio, o aplicativo as presentes normas, especialmente para adaptá-las às evoluções do aplicativo a empresa, seja pela disponibilização de novas funcionalidades, seja pela supressão ou modiﬁcação daquelas já existentes.
            </p>
            <p>
                Esta    Política    de    Privacidade    poderá   ser   atualizada   em   decorrência   de   eventual   atualização normativa, razão pela qual se convida o usuário a consultar periodicamente esta seção.
            </p>
            <p>
                Ao utilizar o serviço após eventuais modiﬁcações, o usuário demonstra sua concordância com as novas normas. Caso discorde de alguma das modiﬁcações, deverá pedir, imediatamente, o cancelamento de sua conta e apresentar sua ressalva ao serviço de atendimento, se assim o desejar.
            </p>
            <h2 style={{ textAlign: "initial" }}>CANAIS DE ATENDIMENTO</h2>
            <p>
                Esta Política de Privacidade aplica-se a todos os serviços e sites oferecidos pela empresa. Em caso de dúvida sobre a presente Política de Privacidade, sinta-se à vontade para entrar em contato por meio dos correios eletrônicos a seguir: privacidade@inhousemarket.com.br
            </p>
            <h2 style={{ textAlign: "initial" }}>DO DIREITO APLICÁVEL E DO FORO</h2>
            <p>
                Para     a     resolução     das     controvérsias     decorrentes     do     presente    instrumento,    será    aplicado integralmente o Direito Brasileiro.
            </p>
            <p>
                Os  eventuais  litígios  deverão  ser  apresentados  no  foro  da  comarca  em  que se encontra a sede do editor do aplicativo.
            </p>
            <h2 style={{ textAlign: "initial" }}>DA CONFIRMAÇÃO</h2>
            <p>
                Após a leitura das condições deste termo, o USUÁRIO reconhece expressamente que leu e entendeu os direitos, obrigações e condições do mesmo, e que ao clicar no botão “Eu li e aceito os Termos de Uso e a Política de Privacidade”, concede a empresa os direitos aqui estabelecidos
            </p>
            <p>&nbsp;</p>
            <p></p>
        </div>
    );
}


export default PrivacyPolicy;