import React from 'react';
import { render } from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";

import { Routes, Route } from 'react-router-dom';
import TermsOfUse from './pages/docs/termsOfUse';
import PrivacyPolicy from './pages/docs/privacyPolicy';

render(
  <BrowserRouter>
    <React.StrictMode>      
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/legal/termos-e-condicoes" element={<TermsOfUse />} />
        <Route path="/legal/politica-de-privacidade" element={<PrivacyPolicy />} />
      </Routes>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root')
);


