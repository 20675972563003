import React from "react";
import logo from '../../logo.png';

function TermsOfUse() {
    return (
        <div style={{ width: "80%", margin: "0 auto", textAlign: "justify" }}>
            <img src={logo} property="og:image" alt="Logo" width="200" height="200" />
            <h1 style={{ textAlign: "initial" }}>
                Termos e Condições de uso
            </h1>
            <p></p>
            <p>
                Por favor, leia com atenção os termos e condições de uso.
            </p>
            <p>
                Ao   se   cadastrar  em  nosso  aplicativo  você  ESTÁ  DE  ACORDO  COM  AS  CONDIÇÕES  E  TERMOS do Aplicativo.
            </p>
            <p>
                A   recusa   destes   Termos   do   aplicativo   impedirá   que   você   faça  pedidos  de  produtos  do  nosso aplicativo.
            </p>
            <p>
                A IHM COMERCIO INTELIGENTE LTDA (CNPJ: 37.232.275/0001-60), doravante “empresa”, garante a segurança e privacidade da identidade a todos os seus USUÁRIOS, sendo estes entendidos como toda e qualquer pessoa interessada direta ou indiretamente, nos serviços e sistemas de propriedade ou gerenciados pela empresa incluindo o aplicativo SHOPPBUD, painel admin SHOPPBUD, portal SHOPPBUD, e quaisquer outros sistemas de propriedade ou gerenciados pela empresa.
            </p>
            <p></p>
            <p>
                1. CLÁUSULA PRIMEIRA - SERVIÇOS FORNECIDOS
            </p>
            <p>
                1.1 Estes termos e condições de uso regulam o acesso e a utilização pelos USUÁRIOS dos aplicativos e sistemas disponibilizados pela empresa, facilitando a escolha de produtos cadastrados e disponíveis, de forma virtual por aplicativo no local da retirada de produtos, realizando assim a escolha de produtos alimentícios, de limpeza e diversos outros produtos e serviços que venham a ser fornecidos pela empresa, em suas unidades autônomas de autoatendimento, conforme portfólio especíﬁco de cada loja. Sendo possível, aos USUÁRIOS, a efetivação do pagamento do valor dos produtos via online pelo Aplicativo ou por totens de autoatendimento.
            </p>
            <p>
                1.2 Os serviços do mercado inteligente da empresa consiste, portanto, em fornecer aos USUÁRIOS cadastrados, uma linha de produtos através do App, possibilitando que os USUÁRIOS realizem compras na própria unidade, de maneira que o próprio USUÁRIO escolhe seus produtos, registra-o com a câmera de seu celular on-line, e realize o pagamento, de maneira prática e segura.
            </p>
            <p>
                1.3 Todos os produtos disponíveis nas Gôndolas serão previamente catalogados no aplicativo para realização das compras com agilidade e segurança. Válido frisar que para o bom funcionamento referente à atualização da listagem de produtos, faz-se necessário a utilização contínua de internet.
            </p>
            <p>
                1.4 Vale lembrar que, toda e qualquer compra deverá ser feita no local da retirada de produtos, tendo em vista que é necessário a utilização da Internet para atualização do portfólio devido a grande rotatividade de produtos, garantindo assim a sua compra.
            </p>
            <p>
                1.5 Desde logo ﬁca esclarecido ao USUÁRIO, que o serviço fornecido pela empresa se relaciona apenas à comercialização (com pagamento on-line) de produtos. Sendo assim, são de TOTAL responsabilidade do USUÁRIO a retirada física do produto, a embalagem, preparo, deslocamento e conservação dos itens escolhidos.
            </p>
            <p>2. CLÁUSULA SEGUNDA - CADASTRO</p>
            <p>
                2.1 Para utilizar os serviços acima descritos, o USUÁRIO deverá ser plenamente capaz para todos os atos civis, qual seja, coberto de capacidade jurídica, devendo prestar as informações exigidas no CADASTRO, assumindo integralmente a responsabilidade cível e criminal pela exatidão e veracidade das informações prestadas no CADASTRO, que poderá ser veriﬁcado, a qualquer momento, pela empresa.
            </p>
            <p>
                2.1.1 Caso haja registro de informações incorretas, inverídicas ou não conﬁrmadas, a empresa se reserva o direito de não concluir o cadastramento em curso ou, ainda, de bloquear o cadastro já existente, impedindo assim o USUÁRIO de utilizar os serviços on-line até que, a critério da empresa, a lide seja normalizada. A empresa se reserva o direito de impedir, a seu critério, novos CADASTROS, ou cancelar os já efetuados, em casos de situações de má-fé detectadas ou tentativas de burlar regras obrigatórias para todos os USUÁRIOS. A empresa tomará todas as medidas cabíveis caso veriﬁque qualquer violação ao descumprimento das regras e obrigações dos USUÁRIOS.
            </p>
            <p>
                2.2 Realizado o cadastro com sucesso, o USUÁRIO só terá acesso aos serviços após validar o link de conﬁrmação encaminhado por email, que após feito isso, criará sua senha para realizar login. A empresa se compromete a não divulgar a terceiros, nenhum dado solicitado no ato cadastral, ﬁcando sob sua exclusiva responsabilidade qualquer solicitação para compra de produtos que seja feita com o uso de login e senha de sua titularidade.
            </p>
            <p>3. CLÁUSULA TERCEIRA - DO MONITORAMENTO REMOTO</p>
            <p>
                3.1 Para ﬁns de segurança e acompanhamento de vendas, a empresa é coberta de monitoramento 24hrs (vinte e quatro horas) por áudio e vídeo em cada unidade de venda, o qual é acionado em horário comercial por operador vinculado a empresa de forma remota.
            </p>
            <p>
                3.2. O USUÁRIO, declara-se expressamente ciente e consente com a utilização deste recurso, permitindo a coleta de dados decorrentes do monitoramento, concedendo ainda as licenças eautorizações necessárias para o uso de sua imagem e voz nos termos do presente “Termo de Uso”, dados estes que serão armazenados pela empresa, em servidores seguros, respeitando a privacidade e direito de imagem dos USUÁRIOS e que podem ser compartilhados com seus licenciados para uso em acordo à Lei Geral De Proteção de DadosPessoais (LGPD).
            </p>
            <p>
                3.3. Os dados coletados no monitoramento não serão compartilhados com terceiros, exceto nas hipóteses descritas: (i) Para órgãos, autoridades e demais entes do poder público, bem como a pessoas físicas ou jurídicas de natureza privada, em cumprimento de obrigação legal ou ordem judicial, (ii) Para autoridade policial ou ao representante do Ministério Público, independentemente da existência de obrigação legal especíﬁca ou ordem judicial, quando, excepcionalmente, houver suspeita fundada de crime com violência ou grave ameaça em circunstância emergencial e envolvendo USUÁRIOS do aplicativo, ou necessários para proteger direito, propriedades ou a segurança da empresa, de seus usuários ou terceiros, (iii) Para os responsáveis ou administradores do local onde ﬁca situado a loja - por exemplo, o síndico ou o administrador.
            </p>
            <p>4. CLÁUSULA QUARTA - DAS OBRIGAÇÕES DO USUÁRIO</p>
            <p>
                4.1 Efetuado com sucesso o CADASTRO do USUÁRIO, este se obriga a não divulgar a terceiros login e senha de acesso, nem permitir o uso de tais informações por terceiros, responsabilizando-se pelas consequências do uso de login e senha de sua titularidade.
            </p>
            <p>
                4.2 É obrigação do USUÁRIO fornecer informações cadastrais totalmente verídicas e exatas, responsabilizando-se exclusiva e integralmente por todo o conteúdo por si informado no item CADASTRO..
            </p>
            <p>
                4.3 O USUÁRIO se obriga, também, a pagar integralmente o preço dos produtos por si solicitados e efetivamente retirados na unidade de autoatendimento, por meio dos sistemas fornecidos pela empresa.
            </p>
            <p>
                4.3.1 Caso o pagamento não venha a ser concluído, seja por falha no aplicativo ou qualquer outra razão, o USUÁRIO, desde já deve se abster de retirar o produto até ser possível realizar o pagamento e entrar em contato com o suporte comunicando a impossibilidade de ﬁnalizar a compra, garantindo assim o correto controle de vendas e continuidade na boa execução dos serviços;
            </p>
            <p>
                4.3.2  Na  hipótese  de  o  USUÁRIO  retirar  um  ou mais produtos sem realizar o pagamento em até 15 minutos após a retirada do produto:
            </p>
            <ul>
                <li>
                    <p>
                        i. A    empresa    poderá    compartilhar    os    dados    do    USUÁRIO,    se    necessário    for,    para identiﬁcá-lo e contatá-lo com o legítimo interesse em reaver o valores devidos;
                    </p>
                </li>
                <li>
                    <p>
                        ii. USUÁRIO estará sujeito a multa de no máximo 5(cinco) vezes o valor de cada produto retirado e bloqueio do seu acesso aos sistemas da empresa;
                    </p>
                </li>
                <li>
                    <p>
                        iii. O usuário autoriza o débito dos valores devidos no cartão de crédito provido ou conta de pagamentos pela empresa após 7 dias de enviada notiﬁcação por escrito no endereço de e-mail provido pelo USUÁRIO;
                    </p>
                </li>
                <li>
                    <p>
                        IV.   O USUÁRIO está ciente de que poderão ser tomadas medidas legais.
                    </p>
                </li>
            </ul>
            <p>
                4.4 O USUÁRIO que seja menor de 18 anos de idade está ciente de que não poderá adquirir, em qualquer hipótese, produtos alcoólicos, responsabilizando-se pela correta informação de sua idade no item CADASTRO, o qual poderá ser veriﬁcado a qualquer momento.
            </p>
            <p>
                4.5 O USUÁRIO concorda com o uso das informações de avaliações e feedbacks dos serviços oferecidos pela empresa, conforme descrito na POLÍTICA DE PRIVACIDADE da empresa , disponível também em nosso site.
            </p>
            <p>
                4.6 O USUÁRIO concorda com o envio de mensagens telemáticas publicitárias pela empresa, fazendo uso de todas as tecnologias e os meios de comunicação disponíveis, seja por e-mail, SMS, WhatsApp, app e outros. Todas as mensagens publicitárias poderão ser inativadas pelo próprio celular em conﬁgurações/notiﬁcações.
            </p>
            <p>5. CLÁUSULA QUINTA - OBRIGAÇÕES DA EMPRESA</p>
            <p>
                5.1 Disponibilizar no app espaço virtual que permita ao USUÁRIO devidamente cadastrado efetivar compra dos produtos anunciados e comercializados pelas unidades de autosserviço e, também, disponibilizar ao USUÁRIO meios de pagamento do preço dos produtos on-line.
            </p>
            <p>
                5.2 Proteger, por meio de armazenamento em servidores ou quaisquer outros meios magnéticos de alta segurança, a conﬁdencialidade de todas as informações e cadastros relativos aos USUÁRIOS, assim como valores atinentes às operações ﬁnanceiras advindas da operacionalização dos serviços previstos no presente TERMO. Contudo, não responderá pela reparação de prejuízos que possam ser derivados de apreensão de dados por parte de terceiros que, rompendo os sistemas de segurança, consigam acessar essas informações.
            </p>
            <p>6. CLÁUSULA SEXTA - ALTERAÇÕES DESTE TERMO</p>
            <p>
                6.1 O presente TERMO DE USO poderá, a qualquer tempo, ter seu conteúdo, ou parte dele, modiﬁcados para adequações e inserções, tudo com vistas ao aprimoramento dos serviços prestados.
            </p>
            <p>
                6.2 Em caso de alteração do TERMO principal, TERMOS adicionais terão a mesma validade para todos os efeitos. Nas hipóteses em que houver conﬂito de informações, os Termos Adicionais prevalecerão apenas no que for conﬂitante aos Termos Anteriores, tendo em vista que, a implementação de Termos adicionais são sempre voltados à condições de melhoria.
            </p>
            <p>7. CLÁUSULA SÉTIMA - CANAL DE COMUNICAÇÃO</p>
            <p>
                7.1 Para estabelecer contato entre a empresa e o USUÁRIO ﬁca disponibilizado o endereço eletrônico deste link, sendo certo que o USUÁRIO se obriga, igualmente, a manter em seu cadastro endereço eletrônico atual por intermédio do qual se farão as comunicações a ele dirigidas pela empresa, desde logo emprestando-se validade jurídica e efetividade a esse meio eletrônico de troca de informações recíprocas.
            </p>
            <p>8. CLÁUSULA OITAVA - ACEITAÇÃO DO TERMO DE USO</p>
            <p>
                8.1 O USUÁRIO declara ter lido, entendido e aceitado expressamente todas as regras, condições e obrigações, sem quaisquer reservas ou ressalvas, estabelecidas no presente TERMO. O USUÁRIO reconhece e concorda que a sua concordância integral com os TERMOS conﬁgura condição essencial para o uso do aplicativo.
            </p>
            <p>9. CLÁUSULA NONA - FORO DE ELEIÇÃO</p>
            <p>9.1 As partes elegem como competente para dirimir eventuais controvérsias que venham a surgir da interpretação e do cumprimento do presente TERMO o foro da Comarca de Fortaleza-CE.</p>
            <p>&nbsp;</p>
            <p></p>
        </div>
    );
}

export default TermsOfUse;